<template>
    <div class="page-content">
        <div  class="row">
            <pre-video src="https://cdn.joeltankard.com/website/projects/facetracker/face-detection.mp4" />
            <div>
                <h2>How it started</h2>
                <p>In early-2020 when COVID-19 hit business started to adopt video call softwares like Zoom. There were a few issues that we're software related. Attendees had messy bedrooms. Zoom backgrounds would key out the entire person, leaving floating eyes and mouths (hilarious). Worst of all, some would even use the bathroom not realising the video was still on.</p>
                <p>How can we video call without the video & without removing the emotion? Allow people to mask their faces. A perk of this would be less call lag, as all that needs to be sent is a few face co-ords & the audio across the network. </p>
          
            </div>
        </div>
        <div id="tracker" class="row row-reverse">
            <img src="https://cdn.joeltankard.com/website/projects/facetracker/tracking-points.png" />
            <div>
                <h2>Tracking a face</h2>
                <p>Using <a href="https://github.com/justadudewhohacks/face-api.js" target="_blank">Face-api.js</a> I was able to track feature points on a persons face. However, in a video call, peoples faces are all different sorts of angles & distances from the camera. This causes measurements of the face & it's features to be skewed. To combat this I first tracked the head, then output a video of the persons face. No matter the distance the face would always be the same size. I then used this video for the tracking.</p>
            </div>
        </div>

        <div id="face" class="row">
            <img src="https://cdn.joeltankard.com/website/projects/facetracker/tracking-face.png" />
            <div>
                <h2>The face design</h2>
                <p>Most video tracking examples use all the points on a persons face, creating a creepy face. To avoid the uncanny valley I took only the basic points needed to express emotions: The angle of the eyebrows, the distance of the eyebrows from the eyes, & the height of the eyes. The mouth was to change shapes based on emotion detected by Face-api.js & use phonemes detected in the audio.</p>
                <p>Users would be able to change there faces using a range of eyebrows, eyes, noses, & mouths. Similar to Nintendo Mii.</p>
            </div>
            
        </div>

        <div class="row">
            <div>
                <h2>Speed limitations</h2>
                <p>I originally wanted this to be browser-based for easy access. I quickly pushed the limits of chrome. A single-thread process was not enough for tracking alone! So I moved to Electron. This allowed me to have a seprate process for Tracking the head, tracking the facial features, & rendering the face.  </p>
            </div>
             <div>
                <h2>Tracking limitations</h2>
                <p>Faces are just too different. When my partner used it her eyes looks the exact size I wanted! When I used it they changed to pin drops (I have typical Celtic eyes.) When she moved her eyebrows nothing happened. When I did it they worked perfectly. <br/> I played around with the settings for hours. If I made it to sensitive to changes in the face everything would start jumping all over the place. If I didn't make it sensitive enough hardly anything would move.</p>
            </div>
         
        </div>

    <div class="outcome">
                    <h2>Outcome</h2>
                    <div class="stats">
                    <div>
                        <h3>Life span</h3>
                        <h4>2 months</h4>
                    </div>
                    <div>
                        <h3>Staus</h3>
                        <h4>Open sourced</h4>
                    </div>
                </div>
                    <div>
                        <h3>Summary</h3>
                        <p>Although it was a fun project it wasn't worth continuing. The app was battery draining. The face didn't work well often enough. Maybe with a lower-level language, I'd have better results. However, I've coded in C# before & I know this is way out of reach for me. I decided to open-source the project and share it on Twitter. Maybe someone else could make this work. Maybe using Rust or WebAssembly it could one day work in the browsers.</p>
                    </div>
                </div>
                    </div>

</template>

<script>
import PreVideo from '@/components/PreVideo';

export default {
    name: 'FaceTracker',
    components: {
        PreVideo
    }
}
</script>